import React, { useEffect, useRef, useState } from "react";
import { SimpleCard } from "../../compontents/SimpleCard";
import { Alert } from "@mui/lab";
import { Usuarios } from "../Usuarios/Usuarios";
import { useParams } from "react-router-dom";
import { Box, Grid, Icon, styled, Badge, Avatar } from "@mui/material";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import ModalCrearUsuario from "../Dashboard/Components/modals/modalCrearUsuario";

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const ListaUsuario = ({ comercio }) => {
  const { usuarios } = comercio;
  const [estilo, setEstilo] = useState(null);
  console.log(comercio, usuarios);
  useEffect(() => {
    // console.log(interfaz)
    if (localStorage.getItem("interfaz")) {
      // console.log(JSON.parse(localStorage.getItem("interfaz")))
      const slug = JSON.parse(localStorage.getItem("interfaz"));
      setEstilo(slug);
    }
  }, []);

  const [page, setPage] = useState(0);
  //TODO: Cambiar en el store el total de registros
  // const [totalRegistros, setTotalRegistros] = useState(1);

  let pages = [];
  const pagesRef = useRef(pages);

  const [usuariosEntidad, setUsuariosEntidad] = useState(usuarios);
  const [totalRegsEntidad, setTotalRegsEntidad] = useState(
    usuarios ? usuarios.length : 0
  );
  const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   dispatch(vaciarUsuarios());
  //   if (id !== undefined) {
  //     pagesRef.current.push(page);
  //     dispatch(startGetUsuariosEntidad(id));
  //     // setSetEntidades(entidades.concat(entidadesComerciales))
  //   }
  // }, [page]);

  const history = useNavigate();

  const createUser = () => {
    setShowModal(true);
  };

  const getPage = (page) => {
    setPage(page);
    //console.log('Page del padre: ', page)
  };

  return (
    <SimpleCard title={"Usuarios entidad"}>
      {totalRegsEntidad === 0 ? (
        <>
          <Alert severity="info">No se han registrado usuarios</Alert>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={5}
                md={3}
                lg={2}
                sx={{
                  border: "4px solid",
                  borderStyle: "dashed",
                  borderRadius: "10px",
                  textAlignLast: "center",
                  boxShadow:
                    "inset 0 -3em 3em rgba(0, 0, 0, 0.1), 0 0  0 2px rgb(255, 255, 255), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3), 0.3em 0.3em 1em rgba(0, 0, 0, 0.3)",
                  margin: "10px 20px 10px 20px",
                  color: !estilo ? "#437294" : estilo.color_principal,
                  minWidth: "233px",
                }}
              >
                <SimpleCard alignItems="center">
                  <Box display="flex" sx={{ padding: "50px 0px 10px 0px" }}>
                    <Box
                      alignItems="center"
                      sx={{
                        display: "inline-block",
                        height: "80px",
                        marginBottom: "16px",
                        marginLeft: "auto",
                        marginRight: "auto",
                        position: "relative",
                        textAlign: "center",
                        width: "80px",
                      }}
                    >
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                      >
                        <Avatar
                          sx={{
                            cursor: "pointer",
                            width: 80,
                            height: 80,
                            background: !estilo
                              ? "#437294"
                              : estilo.color_principal,
                          }}
                        />
                      </Badge>
                    </Box>
                  </Box>
                  <Box
                    sx={{ pt: 3, display: "flex", justifyContent: "center" }}
                  >
                    <StyledButton
                      variant="outlined"
                      type="submit"
                      color="primary"
                      onClick={createUser}
                      sx={{
                        width: "max-content",
                        fontWeight: "bold",
                        border: "2px solid",
                        color: !estilo ? "#437294" : estilo.color_principal,
                        borderRadius: "10px",
                      }}
                    >
                      <Icon sx={{ mr: "10px" }}>add</Icon>
                      Agregar nuevo usuario
                    </StyledButton>
                  </Box>
                </SimpleCard>
              </Grid>
            </Grid>
          </Grid>
          <ModalCrearUsuario
            isVisible={showModal}
            onClose={() => setShowModal(false)}
            negocios={comercio}
          />
        </>
      ) : (
        <Usuarios
          listaEntidades={usuariosEntidad}
          entidad={comercio}
          getPage={getPage}
          totalRegs={totalRegsEntidad}
        />
      )}
    </SimpleCard>
  );
};

export default ListaUsuario;
