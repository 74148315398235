import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import "./styles.css";
import axios from "axios";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const ModalAceptarAnticipo = ({ isVisible, onClose, tarjeta, actualizarPago, user }) => {
  console.log(tarjeta)
  const [informacion_bancaria, setInformacion_bancaria] = useState("");
  const [valor, setValor] = useState("");

  useEffect(() => {
    if (tarjeta) {
      setInformacion_bancaria(JSON.parse(tarjeta.informacion_bancaria));
      let onlytap = parseInt(tarjeta.clic_cobrar) * 5;
      let reviews = parseInt(tarjeta.efectivo_reviews) * 10;
      let menu = parseInt(tarjeta.efectivo_menu);
      setValor(onlytap + reviews + menu);
    }
  }, [tarjeta]);

  if (!isVisible) return null;

  const handleSaveClick = async () => {
    try {
      const objetoJSON = {
        estado: 3
      };

      console.log(objetoJSON);

      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };

      const pago = await axios.post(
        baseUrl + `pagos/anticipos/actualizar/${tarjeta.id}`,
        JSON.stringify(objetoJSON),
        { headers }
      );
      if (pago.status === 201) {
        console.log("Pago actualizado con éxito", pago);
        Swal.fire({
          icon: "success",
          title: "Anticipo acreditado con éxito",
          text: "",
          confirmButtonText: "Aceptar",
        });
      }
    } catch (error) {
      console.error("Error gestionando el anticipo:", error);
    }
  };

  const handleCancel = () => {
    window.dispatchEvent(new Event("storage"));
    onClose();
  };

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div className="modal-container">
        <div className="modal-body">
          <div className="input-container">
            <div className="modal-header">
              <h5>
                <b>Información para el anticipo de: </b> {tarjeta.cantidad}${" "}
              </h5>
              <Button onClick={onClose} className="close-button">
                X
              </Button>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>Nombre:</strong> <br />
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {user.nombres} {user.apellidos}<br />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>Correo:</strong> <br />
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {user.correo} <br />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>Identificación:</strong> <br />
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {informacion_bancaria.identificacion} <br />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>Tarjeta:</strong> <br />
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {tarjeta.user_tarjeta_id} <br />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>Entidad bancaria:</strong> <br />
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {informacion_bancaria.entidad} <br />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>Tipo de cuenta:</strong> <br />
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {informacion_bancaria.tipoCuenta} <br />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>Número de cuenta:</strong> <br />
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {informacion_bancaria.numeroCuenta} <br />
              </div>
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr", // Dos columnas iguales
                gap: "0px", // Espacio entre las columnas
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                <strong>Motivo:</strong>
              </div>

              <div
                style={{
                  backgroundColor: "white",
                }}
              >
                {tarjeta.detalle}
              </div>
            </div>
            <div className="linea-botones">
              <button onClick={handleCancel} className="cancel-button">
                Cancelar
              </button>
              <button
                type="button"
                onClick={handleSaveClick}
                // disabled={estado}
                className="submit-button"
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default ModalAceptarAnticipo;
