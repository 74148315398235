import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import "./styles.css";
import axios from "axios";
import Swal from "sweetalert2";
import Grid from "@mui/material/Grid";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const ModalAnticipoSueldo = ({ isVisible, onClose, maximo }) => {
  const [entidad, setEntidad] = useState("pichincha");
  const [tipoCuenta, setTipoCuenta] = useState("ahorros");
  const [identificacion, setIdentificacion] = useState("");
  const [cantidad, setCantidad] = useState("");
  const [detalle, setDetalle] = useState("");
  const [numeroCuenta, setNumeroCuenta] = useState("");
  const [id, setId] = useState(localStorage.getItem("tarjetaID"));

  const handleDescripcionChange = (event) => {
    const newDescripcion = event.target.value;
    setDetalle(newDescripcion);
  };

  if (!isVisible) return null;

  const handleSaveClick = async () => {
    try {
      const objetoBanco = {
        entidad,
        tipoCuenta,
        identificacion,
        numeroCuenta,
      };

      const objetoJSON = {
        user_tarjeta_id: id,
        detalle,
        informacion_bancaria: JSON.stringify(objetoBanco),
        cantidad,
        estado: 2
      };

      console.log(objetoJSON, id);

      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };

      const pago = await axios.post(
        baseUrl + `pagos/realizados/crear`,
        JSON.stringify(objetoJSON),
        { headers }
      );
      if (pago.status === 201) {
        console.log("Pago realizado con éxito", pago);
        // const response = await axios.post(
        //   baseUrl + `usuario/tarjeta/actualizar/${tarjeta.id}`,
        //   {
        //     clic_cobrar: 0,
        //     clic_pagados:
        //       parseInt(tarjeta.clic_pagados) + parseInt(tarjeta.clic_cobrar),
        //     informacion_bancaria: "",
        //     efectivo_menu: 0,
        //     efectivo_reviews: 0,
        //   },
        //   { headers }
        // );
        // if (response.status === 200) {
        //   actualizarPago(tarjeta.id, tarjeta.clic_cobrar);
        //   Swal.fire({
        //     icon: "success",
        //     title: "Pago realizado con éxito",
        //     text: "",
        //     confirmButtonText: "Aceptar",
        //   });
        //   onClose();
        // }
      }

      // const response = await axios.post(
      //   baseUrl + `usuario/tarjeta/actualizar/${id}`,
      //   { informacion_bancaria: objetoJSON },
      //   { headers }
      // );
      // if (response.status === 200) {
      //   Swal.fire({
      //     icon: "success",
      //     title: "Solicitud ingresada",
      //     text: "En el transcurso de 24 a 48 horas se le notificará el estado de su solicitud",
      //     confirmButtonText: "Aceptar",
      //   });
      //   onClose();
      // }
    } catch (error) {
      console.error("Error al registrar la información bancaria:", error);
    }
  };

  const handleIdentificacionChange = (event) => {
    const newIdentificacion = event.target.value;
    if (newIdentificacion !== undefined) {
      setIdentificacion(newIdentificacion);
    }
  };

  const handleCantidadChange = (event) => {
    const newCantidad = event.target.value;
    if (newCantidad !== undefined) {
      setCantidad(Math.min(newCantidad, maximo));
    }
  };

  const handleNumeroCuentaChange = (event) => {
    const newNumeroCuenta = event.target.value;
    if (newNumeroCuenta !== undefined) {
      setNumeroCuenta(newNumeroCuenta);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Backdrop
      sx={{
        color: "#000",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <div className="modal-container">
        <div className="modal-body">
          <div className="input-container">
            <div className="modal-header">
              <h6>
                Proporcione la información bancaria para recibir su anticipo.
              </h6>
            </div>
            <div className="form-floating">
              <input
                type="number"
                name="cantidad"
                value={cantidad}
                onChange={handleCantidadChange}
                className="input-register my-1"
                max={maximo}
                placeholder="Cantidad"
              />
            </div>
            <Grid container spacing={0}>
              <Grid
                item
                xs={6}
                style={{
                  justifyContent: "flex-end",
                  paddingTop: "0px",
                }}
              >
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="pichincha"
                  name="radio-buttons-group"
                >
                  <b>Entidad financiera</b>
                  <FormControlLabel
                    value="pichincha"
                    control={<Radio />}
                    label="Pichincha"
                    onChange={(event) => setEntidad(event.target.value)}
                  />
                  <FormControlLabel
                    value="produbanco"
                    control={<Radio />}
                    label="Produbanco"
                    onChange={(event) => setEntidad(event.target.value)}
                  />
                </RadioGroup>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  justifyContent: "flex-end",
                  paddingTop: "0px",
                }}
              >
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="ahorros"
                  name="radio-buttons-group"
                >
                  <b>Tipo de cuenta</b>
                  <FormControlLabel
                    value="ahorros"
                    control={<Radio />}
                    label="Ahorros"
                    onChange={(event) => setTipoCuenta(event.target.value)}
                  />
                  <FormControlLabel
                    value="corriente"
                    control={<Radio />}
                    label="Corriente"
                    onChange={(event) => setTipoCuenta(event.target.value)}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
            <div className="form-floating">
              <input
                type="number"
                name="identificacion"
                value={identificacion}
                onChange={handleIdentificacionChange}
                className="input-register my-1"
                placeholder="Identificación"
              />
            </div>
            <div className="form-floating">
              <input
                type="number"
                name="numeroCuenta"
                value={numeroCuenta}
                onChange={handleNumeroCuentaChange}
                className="input-register my-1"
                placeholder="Número de cuenta"
              />
            </div>
            <b>Motivo</b>
            <div className="form-floating">
              <textarea
                type="text"
                name="description"
                value={detalle}
                onChange={handleDescripcionChange}
                className="input-register my-1"
                placeholder=""
                rows="5"
              ></textarea>
            </div>

            <div className="linea-botones">
              <button onClick={handleCancel} className="cancel-button">
                Cancelar
              </button>
              <button
                type="submit"
                onClick={handleSaveClick}
                // disabled={estado}
                className="submit-button"
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default ModalAnticipoSueldo;
