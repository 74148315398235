import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import { Tabs, Tab } from "@mui/material";
import {
  getComparator,
  StyledTableCell,
  StyledTableRow,
  Container,
  stableSort,
} from "../../../compontents/TableFunctions";
import {
  Table,
  TableRow,
  TableCell,
  Paper,
  Box,
  TextField,
  TableBody,
  TableContainer,
  Grid,
  Skeleton,
} from "@mui/material";
import { esES } from "@mui/material/locale";
import { EnhancedTableHead } from "../../../compontents/EnhancedTableHead";
import { usePagination } from "../../../compontents/usePagination";
import { PaginatorServer } from "../../../compontents/Paginator";
import { ModalAsignarComercio } from "./modals/index";
import { ModalVerComercios } from "./modals/index";

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const skeletonArray = Array(10).fill("");

const theme = createTheme(esES);

/**
 * Cabecera de la tabla
 */

const headCells = [
  {
    id: "Nombres",
    numeric: false,
    disablePadding: false,
    label: "Nombres",
  },
  {
    id: "Apellidos",
    numeric: false,
    disablePadding: false,
    label: "Apellidos",
  },
  {
    id: "Negocios",
    numeric: false,
    disablePadding: false,
    label: "Negocios",
  },
];

export const Listado = ({ user, negocios }) => {
  const [role, setRole] = useState(user.rol);
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const [tarjetas, setTarjetas] = React.useState([]);
  const [busqueda, setBusqueda] = useState("");
  const [paginaActual, setPaginaActual] = React.useState(1);
  const elementosPorPagina = 5;
  const numerosPaginaMostrados = 4;
  const [value, setValue] = React.useState(0);
  const [entidad, setEntidad] = useState(null);
  const [open, setOpen] = useState(false);
  const [estilo, setEstilo] = useState(null);
  const [ShowModalAsignarComercio, setShowModalAsignarComercio] =
    useState(false);
  const [usuario, setUsuario] = useState(null);
  const [ShowModalVerComercio, setShowModalVerComercio] = useState(false);

  const roles = [
    "ADMIN",
    "PROPIETARIO",
    "GERENTE",
    "CAJERO",
    "MESERO",
    "COCINERO",
    "USER",
  ];
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [totalRegs, setTotalRegs] = useState(0);
  const [rol, setRol] = React.useState("");

  useEffect(() => {
    const { authorisation } = JSON.parse(localStorage.getItem("user"));
    const userComercios = authorisation?.data?.comercios;
    let rolActualizado = authorisation?.data?.rol; // Asignar rol inicial

    if (userComercios) {
      userComercios.forEach((comercio) => {
        if (comercio.rol !== "USER") {
          rolActualizado = comercio.rol;
        }
      });
    }
    setRol(rolActualizado);
  }, []);

  const getPage = (page) => {
    setPaginaActual(page);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(baseUrl + "usuario/listar");
        setTarjetas(response.data.data);
        localStorage.setItem("usuarios", JSON.stringify(response.data.data));
        setTotalRegs(response.data.data.length);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const handleRequestSort = (event, property) => {
    // console.log('handleRequestSort called')
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Filtrado de tarjetas basado en la pestaña activa y búsqueda
  const filteredTarjetas = tarjetas.filter((tarjeta) => {
    // Aquí se puede expandir la lógica para otros tipos de filtrado según el rol o cualquier otra propiedad.
    const tarjetaRol = tarjeta?.rol?.toLowerCase();
    const busquedaMinusc = busqueda?.toLowerCase();
    const filtroRol =
      value === 0 || tarjetaRol === roles[value - 1]?.toLowerCase();

    return (
      filtroRol &&
      (tarjeta?.nombres?.toLowerCase().includes(busquedaMinusc) ||
        tarjeta?.apellidos?.toLowerCase().includes(busquedaMinusc))
    );
  });

  // Función para manejar cambios en la búsqueda
  const handleChangeBusqueda = (event) => {
    setBusqueda(event.target.value);
    setPaginaActual(1); // Reiniciar la página actual a la primera página
  };

  const obtenerTarjetasPaginadas = () => {
    const indiceInicio = (paginaActual - 1) * elementosPorPagina;
    const indiceFin = indiceInicio + elementosPorPagina;
    return filteredTarjetas.slice(indiceInicio, indiceFin);
  };

  const { page, handleChangePage, loading, registros } = usePagination(
    filteredTarjetas,
    filteredTarjetas.length,
    getPage
  );

  obtenerTarjetasPaginadas()
    .filter((tarjeta) => {
      // Aquí decides cómo filtrar las tarjetas basado en la pestaña activa.
      // Por ejemplo, si la pestaña activa es la de "Admin", solo muestras tarjetas de admins.
      if (value === 1) return tarjeta.rol === "ADMIN";
      if (value === 2) return tarjeta.rol === "PROPIETARIO";
      if (value === 3) return tarjeta.rol === "GERENTE";
      if (value === 4) return tarjeta.rol === "CAJERO";
      if (value === 5) return tarjeta.rol === "MESERO";
      if (value === 6) return tarjeta.rol === "COCINERO";
      if (value === 7) return tarjeta.rol === "USER";
      return true; // Para la pestaña "Todos", no filtras y muestras todo.
    })
    .map((tarjeta, index) => (
      <TableRow key={tarjeta.id}>
        <TableCell>{tarjeta.nombres}</TableCell>
        <TableCell>{tarjeta.apellidos}</TableCell>
        // Continúa con el resto de tu renderizado aquí.
      </TableRow>
    ));

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    // setPage(0);
  };

  const handleEditClick = async (row) => {
    console.log("row: ", row);
    setUsuario(row);
    setShowModalAsignarComercio(true);

    // setEntidad(row);

    // const token = localStorage.getItem("token");
    // const headers = {
    //   "Content-Type": "application/json",
    //   Authorization: `Bearer ${token}`,
    //   Accept: "application/json",
    // };

    // const response = await axios.post(
    //   baseUrl + "usuarios/comercio/listar",
    //   { entidad: row.id },
    //   { headers }
    // );
    // let data = response.data;
    // console.log(data);
    // let dataJSON = JSON.stringify(data);
    // setOpen(true);
    // console.log('iD: ', id)
    // await navigate(`/entidad/detalles/${id}`);
  };

  const handleVerEntidades = async (entidades) => {
    console.log("entidades: ", entidades);
    setUsuario(entidades);
    setShowModalVerComercio(true);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Buscar"
            variant="outlined"
            value={busqueda}
            onChange={handleChangeBusqueda}
            style={{ marginBottom: "10px" }}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <div>
        <Tabs value={value} onChange={handleChange} aria-label="tabs de roles">
          <Tab label="Admin" value={1} />
          <Tab label="Propietario" value={2} />
          <Tab label="Gerente" value={3} />
          <Tab label="Cajero" value={4} />
          <Tab label="Mesero" value={5} />
          <Tab label="Cocinero" value={6} />
          <Tab label="User" value={7} />
          <Tab label="Todos" value={0} />
        </Tabs>

        <Container>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Box with="100%" overflow="auto">
                <ThemeProvider theme={theme}>
                  <>
                    <Paper sx={{ with: "100%", overflow: "scroll" }}>
                      <TableContainer sx={{ maxHeight: "100%" }}>
                        <Table
                          sx={{ minWidth: 650 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={registros.length}
                            headCells={headCells}
                          />

                          <TableBody>
                            {loading &&
                              skeletonArray.map((item, index) => (
                                <StyledTableRow key={index}>
                                  {headCells.map((head) => (
                                    <StyledTableCell key={head.id}>
                                      <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height={20}
                                      />
                                    </StyledTableCell>
                                  ))}
                                </StyledTableRow>
                              ))}
                            {!loading &&
                              filteredTarjetas.map((tarjeta) => (
                                <StyledTableRow key={tarjeta.id}>
                                  <StyledTableCell align="left">
                                    {tarjeta.nombres}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {tarjeta.apellidos}
                                  </StyledTableCell>
                                  <StyledTableCell align="left">
                                    {tarjeta.comercios.length === 0 ? (
                                      <StyledButton
                                        variant="outlined"
                                        type="submit"
                                        color="primary"
                                        onClick={() => handleEditClick(tarjeta)}
                                        sx={{
                                          width: "max-content",
                                          fontWeight: "bold",
                                          border: "2px solid",
                                          color: !estilo
                                            ? "#437294"
                                            : estilo.color_principal,
                                          borderRadius: "10px",
                                        }}
                                      >
                                        Asignar comercio
                                      </StyledButton>
                                    ) : (
                                      <StyledButton
                                        variant="outlined"
                                        type="submit"
                                        color="primary"
                                        onClick={() =>
                                          handleVerEntidades(tarjeta.comercios)
                                        }
                                        sx={{
                                          width: "max-content",
                                          fontWeight: "bold",
                                          border: "2px solid",
                                          color: !estilo
                                            ? "#437294"
                                            : estilo.color_principal,
                                          borderRadius: "10px",
                                        }}
                                      >
                                        Ver Comercios
                                      </StyledButton>
                                    )}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                    <PaginatorServer
                      rowsPerPage={rowsPerPage}
                      handleChangePage={handleChangePage}
                      page={page}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                      length={totalRegs}
                    />
                  </>
                </ThemeProvider>
              </Box>
            </Grid>
          </Grid>
          <ModalAsignarComercio
            isVisible={ShowModalAsignarComercio}
            onClose={() => setShowModalAsignarComercio(false)}
            tarjeta={usuario}
            role={rol}
            negocios={negocios}
          />
          <ModalVerComercios
            isVisible={ShowModalVerComercio}
            onClose={() => setShowModalVerComercio(false)}
            tarjeta={usuario}
          />
        </Container>
      </div>
    </>
  );
};

export default Listado;
