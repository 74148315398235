import { Box, Button, Grid, styled, Icon } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/system";
import React from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiDialogActions from "@mui/material/DialogActions";
import { useState, useEffect } from "react";
import { errorParserFromObject } from "../../utils/parseError";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import axios, { formToJSON } from "axios";
import Swal from "sweetalert2";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const UserInfo = styled("div")(({ theme }) => ({
  paddingRight: "33px",
  paddingLeft: "33px",
  paddingTop: "0",
  paddingBottom: "0",
  textAlign: "center",
}));

const H5 = styled("h5")(({ theme }) => ({
  padding: "0px",
  color: "#053D6B",
  fontSize: "16px",
  fontWeight: "bold",
  marginTop: "10px",
  marginBottom: "10px",
}));

const Parrafo = styled("h5")(({ theme }) => ({
  padding: "0px",
  color: "#242532",
  fontSize: "16px",
  fontWeight: "normal",
  marginTop: "10px",
  marginBottom: "10px",
}));

const Titulo = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#053D6B",
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  "&.root": { margin: 0, padding: theme.spacing(1) },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

export default function ModalDesactivar({ id, handleClick }) {
  const [openSn, setOpenSn] = React.useState(false);

  function handleCloseSn(_, reason) {
    if (reason === "clickaway") {
      return;
    }
    setOpenSn(false);
    handleClose();
  }

  const [openEr, setOpenEr] = React.useState(false);

  function handleCloseEr(_, reason) {
    if (reason === "clickaway") {
      return;
    }
    setOpenEr(false);
    handleClose();
  }

  const history = useNavigate();

  const [estilo, setEstilo] = useState(null);

  const [cargando, setCargando] = useState(false);

  // console.log("user,contact", user, contacto)

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  function handleClickOpen(e) {
    setOpen(true);
  }

  function handleClose() {
    handleClick();
    setOpen(false);
  }

  const handleDesactivate = async () => {
    setCargando(true);
    // setCargando(true);
    console.log("Editar", id);

    const editar = {
      estado: false,
    };

    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };

      const response = await axios.post(
        baseUrl + `promociones/fidelizacion/borrar?id=${id}`,
        {},
        { headers }
      );
      if (response.status === 200) {
        handleClose();
        Swal.fire({
          icon: "success",
          title: "Promoción borrada",
          text: "La promoción ha sido borrada con éxito",
          confirmButtonText: "Aceptar",
        });
        return;
      }
    } catch (error) {
      console.log("Error al borrar la promo: ", error.message);
      // Swal.fire('Error', 'Error al eliminar la promo', 'error');
      handleClose();
      return;
    }
  };

  useEffect(() => {
    // console.log(interfaz)
    if (localStorage.getItem("interfaz")) {
      // console.log(JSON.parse(localStorage.getItem("interfaz")))
      const slug = JSON.parse(localStorage.getItem("interfaz"));
      setEstilo(slug);
    }
  }, []);

  return (
    <Box>
      <StyledButton
        variant="outlined"
        type="submit"
        color="secondary"
        onClick={(e) => handleClickOpen(e)}
        sx={{
          width: "max-content",
          fontWeight: "bold",
          border: "2px solid #DA4040",
          borderRadius: "10px",
          color: "crimson",
        }}
      >
        <Icon sx={{ mr: "10px" }}>delete</Icon>
        Eliminar promo
      </StyledButton>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Titulo id="responsive-dialog-title" sx={{ background: "#ffffff" }}>
          <IconButton
            aria-label="Close"
            className="closeButton"
            onClick={handleClose}
            sx={{
              color: theme.palette.grey[500],
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
            }}
          >
            <CloseIcon />
          </IconButton>

          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
            <UserInfo>
              <Box display="flex" alignItems="center">
                <Box
                  alignItems="center"
                  sx={{
                    display: "inline-block",
                    height: "169px",
                    marginBottom: "0px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    position: "relative",
                    textAlign: "center",
                    width: "200px",
                  }}
                >
                  <img
                    src="/assets/images/adn/warningicon.png"
                    width="100%"
                    sx={{
                      justifySelf: "center",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              </Box>
            </UserInfo>
          </Grid>
        </Titulo>

        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ margin: 0 }}>
          <H5 sx={{ margin: 0, position: "relative", textAlign: "center" }}>
            ¿Estas seguro?
          </H5>

          <Parrafo
            sx={{ margin: 0, position: "relative", textAlign: "center" }}
          >
            ¡No podrás revertir esta acción!
          </Parrafo>
        </Grid>

        <DialogActions>
          <StyledButton
            disabled={cargando}
            variant="outlined"
            type="submit"
            color="secondary"
            onClick={handleClose}
            sx={{
              width: "max-content",
              fontWeight: "bold",
              border: "2px solid #DA4040",
              borderRadius: "10px",
              color: "crimson",
            }}
          >
            No, cancelar
          </StyledButton>

          <StyledButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={cargando}
            onClick={handleDesactivate}
            sx={{
              color: "#ffffff",
              width: "max-content",
              background: !estilo ? "#053D6B" : estilo.color_principal,
              borderRadius: "10px",
              fontWeight: "bold",
            }}
          >
            Si, borrar
          </StyledButton>
        </DialogActions>

        <Snackbar open={openSn} autoHideDuration={6000} onClose={handleCloseSn}>
          <Alert
            onClose={handleCloseSn}
            severity="success"
            sx={{ width: "100%" }}
            variant="filled"
          >
            Promo eliminada con éxito!
          </Alert>
        </Snackbar>

        <Snackbar open={openEr} autoHideDuration={6000} onClose={handleCloseEr}>
          <Alert
            onClose={handleCloseEr}
            sx={{ m: 1 }}
            severity="error"
            variant="filled"
          >
            Ocurrio un error, no se pudo eliminar la promo!
          </Alert>
        </Snackbar>
      </Dialog>
    </Box>
  );
}
