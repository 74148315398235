import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Tarjeta from "../../Presentasion/Tarjeta";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import WhatsAppButton from "../../../compontents/WhatsAppButton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";

const Target = () => {
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const [info, setInfo] = React.useState(null);
  const tarjetaId = localStorage.getItem("tarjetaID");

  const [nombre, setNombre] = React.useState("");
  const [perfil, setPerfil] = React.useState("");
  const [fotoPortada, setFotoPortada] = React.useState("");
  const [profesion, setProfesion] = React.useState("");
  const [empresa, setEmpresa] = React.useState("");
  const [twitterUrl, setTwitterUrl] = React.useState("");
  const [facebookUrl, setFacebookUrl] = React.useState("");
  const [youtubeUrl, setYoutubeUrl] = React.useState("");
  const [tiktokUrl, setTiktokUrl] = React.useState("");
  const [linkedinUrl, setLinkedinUrl] = React.useState("");
  const [instagramUrl, setInstagramUrl] = React.useState("");
  const [telegram, setTelegram] = React.useState("");
  const [whatsapp, setWhatsapp] = React.useState("");
  const [url, setUrl] = React.useState("");
  const [titulo, setTitulo] = React.useState("");
  const [direccion, setDireccion] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [telefono, setTelefono] = React.useState("");
  const [shareUrl, setShareUrl] = React.useState("");
  const [rol, setRol] = React.useState("");
  const [showInput, setShowInput] = useState(false);
  const [abrir, setAbrir] = React.useState(false);
  const [codigo, setCodigo] = useState("");
  const [feedback, setFeedback] = useState(null);
  const [user, setUser] = useState(null);
  const [tarjetas, setTarjetas] = React.useState([]);

  const buttonColor = showInput ? "secondary" : "primary";

  useEffect(() => {
    fetchDatos();
  }, [tarjetaId]);

  useEffect(() => {
    const { authorisation } = JSON.parse(localStorage.getItem("user"));
    const userDataJSON = localStorage.getItem("user");
    if (userDataJSON) {
      const { authorisation } = JSON.parse(userDataJSON);
      localStorage.setItem(
        "tarjetaID",
        authorisation?.data?.user_tarjeta[0]?.id
      );
      setUser(authorisation?.data);
    }

    //console.log(authorisation.data.rol)
    setRol(authorisation.data.rol);
    const shareUrl = `https://onlytap.proatek.com/OnlyTap/Presentacion/${tarjetaId}`; // URL que deseas compartir
    setShareUrl(shareUrl);
    fetchDatos();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(baseUrl + "usuario/tarjeta/listar");
        let datos = response.data.data;
        let datosJSON = JSON.stringify(datos);
        localStorage.setItem("allCards", datosJSON);
        setTarjetas(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const fetchDatos = async () => {
    try {
      const { data } = await axios.post(
        baseUrl + `usuario/tarjeta/ver/${tarjetaId}`,
        null // aquí debe ir el body, pero en este caso no es necesario enviar datos adicionales
      );

      const socialMedia = data?.data?.sociales_tarjeta;

      console.log(data.data);

      const twitterUrlObject = socialMedia?.find(
        (social) => social.text_label === "twitter"
      );
      const twitterUrl = twitterUrlObject ?? "";

      const facebookUrlObject = socialMedia?.find(
        (social) => social.text_label === "facebook"
      );
      const facebookUrl = facebookUrlObject ?? "";

      const youtubeUrlObject = socialMedia?.find(
        (social) => social.text_label === "youtube"
      );
      const youtubeUrl = youtubeUrlObject ?? "";

      const tiktokUrlObject = socialMedia?.find(
        (social) => social.text_label === "tiktok"
      );
      const tiktokUrl = tiktokUrlObject ?? "";

      const instagramUrlObject = socialMedia?.find(
        (social) => social.text_label === "instagram"
      );
      const instagramUrl = instagramUrlObject ?? "";

      const linkedinUrlObject = socialMedia?.find(
        (social) => social.text_label === "linkedin"
      );
      const linkedinUrl = linkedinUrlObject ?? "";

      setNombre(data?.data?.nombre);
      setPerfil(data?.data?.img_perfil);
      setFotoPortada(data?.data?.img_portada);
      setProfesion(data?.data?.profesion);
      setEmpresa(data?.data?.empresa);
      setTwitterUrl(twitterUrl);
      setFacebookUrl(facebookUrl);
      setYoutubeUrl(youtubeUrl);
      setTiktokUrl(tiktokUrl);
      setInstagramUrl(instagramUrl);
      setLinkedinUrl(linkedinUrl);
      setTelegram(data?.data?.telegram);
      setWhatsapp(data?.data?.whatsapp);
      setUrl(data?.data?.sitio_web);
      setTitulo(data?.data?.titulo);
      setDireccion(data?.data?.direccion);
      setCorreo(data?.data?.correo);
      setTelefono(data?.data?.telefono);

      setInfo(data.data);
      localStorage.setItem("Tarjeta", JSON.stringify(data.data));
    } catch (error) {
      console.error(error);
    }
  };

  const editarDatos = () => {
    if (nombre !== null) {
      localStorage.setItem("Nombre", nombre);
    }

    if (perfil !== null) {
      localStorage.setItem("FotoPerfil", perfil);
    }

    if (fotoPortada !== null) {
      localStorage.setItem("FotoPortada", fotoPortada);
    }

    if (profesion !== null) {
      localStorage.setItem("Profesion", profesion);
    }

    if (empresa !== null) {
      localStorage.setItem("Descripcion", empresa);
    }

    if (twitterUrl !== null && twitterUrl !== "") {
      localStorage.setItem("Twitter", JSON.stringify(twitterUrl));
    }

    if (facebookUrl !== null && facebookUrl !== "") {
      localStorage.setItem("Facebook", JSON.stringify(facebookUrl));
    }

    if (youtubeUrl !== null && youtubeUrl !== "") {
      localStorage.setItem("Youtube", JSON.stringify(youtubeUrl));
    }

    if (tiktokUrl !== null && tiktokUrl !== "") {
      localStorage.setItem("Tiktok", JSON.stringify(tiktokUrl));
    }

    if (instagramUrl !== null && instagramUrl !== "") {
      localStorage.setItem("Instagram", JSON.stringify(instagramUrl));
    }

    if (linkedinUrl !== null && linkedinUrl !== "") {
      localStorage.setItem("Linkedin", JSON.stringify(linkedinUrl));
    }

    if (telegram !== null) {
      localStorage.setItem("Telegram", telegram);
    }

    if (whatsapp !== null) {
      localStorage.setItem("Whatsapp", whatsapp);
    }

    if (url !== null) {
      localStorage.setItem("Url", url);
    }

    if (titulo !== null) {
      localStorage.setItem("Titulo", titulo);
    }

    if (direccion !== null) {
      localStorage.setItem("Ubicacion", direccion);
    }

    if (correo !== null) {
      localStorage.setItem("Mail", correo);
    }

    if (telefono !== null) {
      localStorage.setItem("Phone", telefono);
    }

    window.location.href = "/OnlyTap/CardEdit";
  };

  const handleClick = () => {
    setShowInput(!showInput);
  };

  const handleVincular = () => {
    setAbrir(true);
    handleUpdate();
  };

  const handleUpdate = async (e) => {
    // console.log(codigo);
    try {
      const token = localStorage.getItem("token");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      };
      const { data } = await axios.post(
        baseUrl + `verificar-id/${codigo}`,
        null, // aquí debe ir el body, pero en este caso no es necesario enviar datos adicionales
        config // aquí se pasa el objeto `config` como segundo parámetro
      );
      // console.log(data);
      if (data.existe) {
        // console.log(1);
        if (data?.data?.usuario_id) {
          // console.log(2);
          setFeedback(
            "Esta tarjeta pertenece a otro usuario, revisa tu código"
          );
          setAbrir(false);
        } else {
          // console.log(3);
          // console.log(codigo, user.id, baseUrl);
          const update = await axios.post(
            baseUrl + `usuario/tarjeta/actualizar/${codigo}`,
            {
              usuario_id: "" + user.id,
              nombre: user.nombres + " " + user.apellidos,
              correo: user.correo,
            },
            config
          );
          if (update.status === 200) {
            toast.success("Tarjeta vinculada con éxito");
            localStorage.setItem("tarjetaID", update?.data?.data?.id);
            fetchDatos();
          }
          // console.log(update);
        }
        setAbrir(false);
      } else {
        // console.log(4);
        setFeedback(
          "No existe una tarjeta con ese código, vuelve a intentarlo"
        );
        setAbrir(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <Grid sx={{ background: "#1565C0", borderRadius: "50px 50px 50px 50px" }}>
        {info !== null && (
          <>
            <Button
              onClick={editarDatos}
              variant="contained"
              endIcon={<EditIcon />}
              sx={{
                width: "94%",
                margin: "0px",
                borderRadius: "5000px 5000px 0px 0px",
                color: "white",
                bgcolor: "transparent",
                boxShadow: "none",
              }}
            >
              Editar
            </Button>
            <Tarjeta data={info} />
          </>
        )}

        {tarjetaId === "undefined" ? <WhatsAppButton /> : <div />}

        {tarjetaId === "undefined" ? (
          <div>
            <Button
              onClick={handleClick}
              color={buttonColor}
              fullWidth
              variant="contained"
              sx={{ mb: 2, width: "100%", marginBottom: "8px" }} // Añadimos un margen inferior de 8px
            >
              Tengo código de tarjeta
            </Button>
            {showInput && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="codigo"
                  label="Codigo de tarjeta"
                  name="codigo"
                  autoComplete="codigo"
                  onChange={(event) => setCodigo(event.target.value)}
                />
                <Button
                  onClick={handleVincular}
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  VINCULAR
                </Button>
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={abrir}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Grid>
            )}
            {feedback && (
              <div style={{ color: "red", marginTop: "10px" }}>{feedback}</div>
            )}
          </div>
        ) : (
          <div />
        )}
      </Grid>
    </Grid>
  );
};

export default Target;
