import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Button, InputLabel, Input, CircularProgress } from "@mui/material";
import { uploadFileToFB } from "../../../firebaseConfig";
import axios from "axios";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const UploadFormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "16px",
  border: "2px dashed",
  borderColor: "#3f51b5",
  borderRadius: "4px",
  backgroundColor: "#f5f5f5",
  color: "#616161",
  outline: "none",
  transition: "border .24s ease-in-out",
  "&:hover": {
    borderColor: "#283593",
  },
});

const inputStyles = {
  width: "100%",
  borderRadius: "10px",
  backgroundColor: "#F5F5F5",
  padding: "10px",
  border: "1px solid #ccc",
};

function Form({ entidad, promo, onPromocionGuardada }) {
  console.log("entidad", entidad);
  console.log("promocion", promo);
  const [titulo, setTitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [puntos, setPuntos] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imgFile, setImgFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  // Cargar los datos de la promoción si existe para editar
  useEffect(() => {
    if (promo) {
      setTitulo(promo.nombre || "");
      setDescripcion(promo.descripcion || "");
      setPuntos(promo.puntos || "");
      setImageUrl(promo.imagen || "");
      setShowImage(true);
      setIsEdit(true);
    }
  }, [promo]);
  const handleRemoveImage = () => {
    setImageUrl("");
    setShowImage(false);
    setImgFile(null);
    setLoading(false);
    setErrorMessage("");
  };

  const handleCancel = (promocion) => {
    console.log("Promoción guardada:", promocion);
    onPromocionGuardada(promocion);
    setTitulo("");
    setDescripcion("");
    setPuntos("");
    setImageUrl("");
    setShowImage(false);
    setImgFile(null);
    setLoading(false);
    setErrorMessage("");
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const validExts = ["png", "jpg", "jpeg"];

    if (file) {
      const ext = file.name.split(".").pop().toLowerCase();
      if (!validExts.includes(ext)) {
        Swal.fire(
          "Error",
          `El archivo con extensión .${ext} no está permitido como imagen`,
          "error"
        );
        setLoading(false);
        return;
      }
      setImgFile(file);
      const url = URL.createObjectURL(file);
      setImageUrl(url);
      setShowImage(true);
    }
  };

  const handleSaveClick = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (isEdit) {
      if (!titulo || !descripcion || !puntos) {
        setLoading(false);
        Swal.fire("Error", "Por favor, complete todos los campos.", "error");
        return;
      }
    } else {
      if (!titulo || !descripcion || !puntos || !imgFile) {
        setLoading(false);
        Swal.fire("Error", "Por favor, complete todos los campos.", "error");
        return;
      }
    }

    try {
      let uploadedUrl = imageUrl;

      if (imgFile) {
        const uploadResult = await uploadFileToFB(imgFile, "storagePromoes");
        uploadedUrl = uploadResult.url || imageUrl;
      }

      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };

      // Si estamos editando, hacemos una solicitud PUT
      const url = isEdit
        ? `${baseUrl}promociones/fidelizacion/actualizar/${promo.id}`
        : `${baseUrl}promociones/fidelizacion/crear`;

      const payload = {
        nombre: titulo,
        puntos: puntos,
        imagen: uploadedUrl,
        descripcion: descripcion,
        tipo:
          entidad?.configuracion_puntos?.length > 0
            ? entidad?.configuracion_puntos[0]?.tipo
            : "GLOBALES",
        comercio_id: entidad?.id || "",
      };

      const response = isEdit
        ? await axios.put(url, payload, { headers })
        : await axios.post(url, payload, { headers });

      if (response.status === 201 || response.status === 200) {
        Swal.fire(
          "Éxito",
          `La promoción ha sido ${isEdit ? "editada" : "creada"} con éxito.`,
          "success"
        );
        handleCancel(response.data.data);
      } else {
        throw new Error("Error desconocido");
      }
    } catch (error) {
      Swal.fire(
        "Error",
        `Ha ocurrido un error al ${
          isEdit ? "editar" : "crear"
        } la promoción. Intente nuevamente.`,
        "error"
      );
      handleCancel(error);
      console.error("Error al guardar la promoción:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      style={{
        borderRadius: "10px",
        border: "1px solid #E0E0E0",
        padding: "20px",
        backgroundColor: "white",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="row">
        <div className="col">
          <h5>Título Promoción</h5>
          <input
            type="text"
            name="titulo"
            value={titulo}
            onChange={(e) => setTitulo(e.target.value)}
            className="input-register"
            style={inputStyles}
            required
          />
        </div>
        <div className="col">
          <h5>$ Valor Promoción</h5>
          <input
            type="number"
            name="puntos"
            value={puntos}
            onChange={(e) => setPuntos(e.target.value)}
            className="input-register"
            style={inputStyles}
            required
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <h5>Descripción</h5>
          <textarea
            name="descripcion"
            value={descripcion}
            onChange={(e) => setDescripcion(e.target.value)}
            className="input-register"
            rows="5"
            style={inputStyles}
            required
          ></textarea>
        </div>
        <div className="col">
          <h5>Foto de la promo</h5>
          {showImage ? (
            <div className="">
              <div className="image-preview-container">
                <img src={imageUrl} alt="Preview" width={200} height={200} />
                <button
                  type="button"
                  className="remove-image-button"
                  onClick={handleRemoveImage}
                >
                  X
                </button>
              </div>
            </div>
          ) : (
            <UploadFormContainer>
              <InputLabel htmlFor="upload-input">
                Subir imagen de promo
              </InputLabel>
              <Input
                id="upload-input"
                type="file"
                style={{ display: "none" }}
                onChange={handleImageChange}
                className="input-register"
              />
              <label htmlFor="upload-input">
                <Button variant="contained" color="primary" component="span">
                  Seleccionar imagen
                </Button>
              </label>
              <p>
                Archivos permitidos:{" "}
                <span style={{ color: "blue" }}>
                  "png", "jpg", "jpeg", ".PNG"
                </span>
              </p>
            </UploadFormContainer>
          )}
        </div>
      </div>

      {errorMessage && <div className="error-message">{errorMessage}</div>}

      <div className="linea-botones">
        <button onClick={handleCancel} className="cancel-button">
          Vaciar
        </button>
        <button
          type="button"
          onClick={handleSaveClick}
          className="submit-button"
        >
          {isEdit ? "Editar" : "Guardar"}
        </button>
      </div>
    </div>
  );
}

export default Form;
