import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import "./styles.css";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Swal from "sweetalert2";
import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { Tabs, Tab } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";

const StyledTable = styled(Table)`
  background-color: white;
`;

const StyledTableHeader = styled(TableCell)`
  background-color: lightgray;
  font-weight: bold;
`;

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const styleContainer = {
  display: "flex",
  justifyContent: "space-between",
  padding: "0px 10px 0px 10px",
};

const styleGanados = {
  backgroundColor: "#d4edda", // verde claro
  padding: "10px",
  borderRadius: "5px",
  margin: "5px",
};

const styleCanjeados = {
  backgroundColor: "#D3D3D3", // rojo claro
  padding: "10px",
  borderRadius: "5px",
  margin: "5px",
};

const styleDisponibles = {
  backgroundColor: "#ffffff", // amarillo claro
  padding: "10px",
  borderRadius: "5px",
  margin: "5px",
};

const ModalVerPuntos = ({ isVisible, onClose, pagos, puntosG }) => {
  console.log(pagos);
  const [paginaActual, setPaginaActual] = React.useState(1);
  const elementosPorPagina = 5;
  const numerosPaginaMostrados = 4;
  const [tarjetas, setTarjetas] = React.useState(pagos);
  const [tabValue, setTabValue] = React.useState(0);

  const pagosFiltrados = pagos.filter((pago) => {
    if (tabValue === 0) {
      return parseInt(JSON.parse(pago.estado)) === 0;
    } else if (tabValue === 1) {
      return parseInt(JSON.parse(pago.estado)) === 1;
    } else if (tabValue === 2) {
      return parseInt(JSON.parse(pago.estado)) === 2;
    } else if (tabValue === 3) {
      return parseInt(JSON.parse(pago.estado)) === 3;
    }
  });

  const [sumaEstadoCero, setSumaEstadoCero] = useState(0);
  const [sumaEstadoUno, setSumaEstadoUno] = useState(0);

  useEffect(() => {
    const { sumaCero, sumaUno } = pagos.reduce(
      (acc, pago) => {
        console.log(pago);
        if (parseInt(pago.estado) === 0) {
          console.log(pago.total_puntos);
          acc.sumaCero += parseFloat(pago.total_puntos);
        } else if (parseInt(pago.estado) === 2) {
          console.log(pago.total_puntos);
          acc.sumaUno += parseFloat(pago.total_puntos);
        }
        return acc;
      },
      { sumaCero: 0, sumaUno: 0 }
    );
    console.log(sumaCero);
    console.log(sumaUno);
    setSumaEstadoCero(sumaCero);
    setSumaEstadoUno(sumaUno);
  }, [isVisible]);

  const indiceInicio = (paginaActual - 1) * elementosPorPagina;
  const indiceFin = indiceInicio + elementosPorPagina;
  const pagosMostrados = pagosFiltrados.slice(indiceInicio, indiceFin);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const irAPagina = (numeroPagina) => {
    setPaginaActual(numeroPagina);
  };

  const irAPaginaAnterior = () => {
    if (paginaActual > 1) {
      setPaginaActual(paginaActual - 1);
    }
  };

  const irAPaginaSiguiente = () => {
    const totalPages = Math.ceil(tarjetas.length / elementosPorPagina);
    if (paginaActual < totalPages) {
      setPaginaActual(paginaActual + 1);
    }
  };
  if (!isVisible) return null;

  const totalPages = Math.ceil(tarjetas.length / elementosPorPagina);
  const indiceInicioNumerosPagina = Math.max(paginaActual - 1, 0);
  const numerosPagina = Array.from(
    { length: numerosPaginaMostrados },
    (_, index) => index + indiceInicioNumerosPagina + 1
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedDay = day < 10 ? "0" + day : day;
    const formattedMonth = month < 10 ? "0" + month : month;
    const formattedHours = hours < 10 ? "0" + hours : hours;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    return `${formattedHours}:${formattedMinutes} ${formattedDay}/${formattedMonth}/${year}`;
  };

  return (
    <Backdrop
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      {" "}
      <div className="modal-container">
        <div className="model-body">
          <div className="modal-header">
            {puntosG && (
              <div style={styleContainer}>
                <p style={styleGanados}>Ganados: {sumaEstadoCero}</p>
                <p style={styleCanjeados}>Canjeados: {sumaEstadoUno}</p>
                <p style={styleDisponibles}>
                  <b>Disponibles: {sumaEstadoCero - sumaEstadoUno}</b>
                </p>
              </div>
            )}
            <Button onClick={onClose} className="close-button">
              X
            </Button>
          </div>
          <div>
            <div>
              <Tabs value={tabValue} onChange={handleTabChange} centered>
                <Tab label="Ganados Globales" />
                <Tab label="Ganados Local" />
                <Tab label="Canjeados Globales" />
                {/* <Tab label="Canjeados Local" /> */}
              </Tabs>
            </div>
            <div>
              <StyledTable>
                <TableHead>
                  <TableRow>
                    <StyledTableHeader>Puntos</StyledTableHeader>
                    <StyledTableHeader>Fecha</StyledTableHeader>
                    <StyledTableHeader>Detalle</StyledTableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pagosMostrados.map((pago) => (
                    <TableRow key={pago.id}>
                      <TableCell>
                        <b>{parseFloat(JSON.parse(pago.total_puntos))}</b>
                      </TableCell>
                      <TableCell>
                        {pago.puntos.map((puntos, index) => (
                          <div key={index}>
                            {formatDate(puntos.created_at)}{" "}
                            {/* Aquí se muestra la fecha de cada punto */}
                          </div>
                        ))}
                      </TableCell>
                      <TableCell>
                        {pago.puntos.map((puntos, index) => (
                          <div key={index}>
                            <b>{puntos.cantidad} Puntos por: </b>
                            {puntos.detalle}
                            <b> en: </b>
                            {puntos.comercio?.nombre_comercial}
                            {/* Estado: {puntos.estado} */}
                          </div>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </StyledTable>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "10px",
                }}
              >
                <Button
                  disabled={paginaActual === 1}
                  onClick={irAPaginaAnterior}
                >
                  <KeyboardArrowLeft />
                </Button>
                <div style={{ display: "flex" }}>
                  {numerosPagina.map((numero) => (
                    <Button
                      key={numero}
                      variant={
                        numero === paginaActual ? "contained" : "outlined"
                      }
                      onClick={() => irAPagina(numero)}
                    >
                      {numero}
                    </Button>
                  ))}
                </div>
                <Button
                  disabled={paginaActual === totalPages}
                  onClick={irAPaginaSiguiente}
                >
                  <KeyboardArrowRight />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default ModalVerPuntos;
