import styles from "./MultiStepFrom.module.css";
import SignUp from "./LayoutRegister";

function StepForm(mail) {
  return (
    <form className={styles.form}>
      <SignUp></SignUp>
    </form>
  );
}

export default StepForm;
