import React, { useEffect, useState } from "react";
import { SimpleCard } from "../../../../compontents/SimpleCard";
import { Alert } from "@mui/lab";
import { Icon, Box } from "@mui/material";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { UsuariosTable } from "./UsuariosTable";
import { CardDetalleUsuario } from "./CardDetalleUsuario";

const ListaUsuarios = ({
  tarjeta,
  handleClose,
  negocio,
  handleConercioCreado,
}) => {
  console.log(tarjeta);
  const [registro, setRegistro] = useState([tarjeta]);
  const [tarjetas, setTarjetas] = useState([]);
  const [noregistrados, setNoRegistrados] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [values, setValues] = useState({ identificacion: "" });

  const [filteredSolicitudes, setFilteredSolicitudes] = useState([]);

  const validInputChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Función para realizar la búsqueda de solicitudes por nombre, apellidos e identificación
    const filterSolicitudes = (filtro) => {
      const cleanFiltro = filtro.trim().toLowerCase();
      const filtered = tarjeta.filter((solicitud) => {
        const solicitudIdentificacion = String(
          solicitud?.identificacion
        ).toLowerCase();
        const solicitudNombre = solicitud?.nombres?.toLowerCase();
        const solicitudApellidos = solicitud?.apellidos?.toLowerCase();
        const nombresYApellidos = `${solicitudNombre} ${solicitudApellidos}`;
        return (
          solicitudIdentificacion.includes(cleanFiltro) ||
          nombresYApellidos.includes(cleanFiltro)
        );
      });
      setFilteredSolicitudes(filtered);
      setShowAlert(filtered.length === 0);
    };

    // Realizar la búsqueda en cada cambio de los campos de identificación, nombre o apellidos
    const { identificacion } = values;
    const filtro = `${identificacion || ""}`;
    filterSolicitudes(filtro);
    if (!filtro.trim()) {
      // Si todos los campos están vacíos, mostrar todas las tarjetas
      setFilteredSolicitudes(tarjeta);
      setShowAlert(false);
    }
  }, [tarjeta, values.identificacion, values.nombre, values.apellidos]);

  const handleSubmit = async () => {
    await setLoading(true);
    const identificacionFilter = tarjetas.filter((solicitud) =>
      solicitud?.identificacion.includes(values.identificacion)
    );

    setFilteredSolicitudes(identificacionFilter);
    await setLoading(false);

    setShowAlert(identificacionFilter.length === 0);
  };

  useEffect(() => {
    const fetchData = async () => {
      await setLoading(true);
      await setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      <SimpleCard title={"Ingrese el número de identificación"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 1,
            width: "100%",
          }}
        >
          {/* CAJA DE BÚSQUEDA */}
          <Box sx={{ width: "100%" }}>
            <ValidatorForm onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  width: "100%",
                  flexWrap: "wrap",
                }}
              >
                <TextValidator
                  value={values.identificacion}
                  onChange={validInputChange}
                  label="Identificacion"
                  name="identificacion"
                  fullWidth
                  validators={["required"]}
                  errorMessages={["Este campo es requerido"]}
                />
              </Box>
            </ValidatorForm>
          </Box>
          {/* TABLA */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 2,
            }}
          >
            {/* TODO: Validar si no hay registros */}
            {showAlert && tarjeta.length === 0 && (
              <Alert severity="info">{`No hay usuarios disponibles`}</Alert>
            )}

            {filteredSolicitudes.length === 0 && (
              <Alert severity="info">
                {`No existen usuarios con esa cédula`}
              </Alert>
            )}

            {/* Mostrar las tarjetas filtradas */}
            {filteredSolicitudes.length === 1 && (
              <CardDetalleUsuario
                solicitud={filteredSolicitudes[0]}
                tarjeta={tarjeta.tarjeta}
                handleClose={handleClose}
                // limpiarSensor={limpiarSensor}
              />
            )}

            {filteredSolicitudes.length > 1 && (
              <UsuariosTable
                listaEntidades={filteredSolicitudes}
                getPage={() => {}}
                totalRegs={filteredSolicitudes.length} // Utilizamos la longitud de las tarjetas filtradas
                tarjeta={registro}
                handleClose={handleClose}
                negocio={negocio}
                handleConercioCreado={handleConercioCreado}
              />
            )}
          </Box>
        </Box>
      </SimpleCard>
    </>
  );
};

export default ListaUsuarios;
