import React, { useEffect, useRef, useState } from 'react'
import { SimpleCard } from '../../compontents/SimpleCard'
import { Alert } from '@mui/lab';
import { AccesosTableModal } from './AccesosTableModal';
import Button from "@mui/material/Button";
import axios from "axios";
import Swal from "sweetalert2";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const ListaAnticiposUsuario = ({ anticipos, user }) => {
  console.log(user);
  const [page, setPage] = useState(0);
  //TODO: Cambiar en el store el total de registros
  // const [totalRegistros, setTotalRegistros] = useState(1);
  const [accesosUser, setAccesosUser] = useState(anticipos);

  let pages = [];
  const pagesRef = useRef(pages);

  // useEffect(() => {

  //   if (!pagesRef.current.includes(page)) {
  //     pagesRef.current.push(page)
  //     dispatch(startGetAccesosUser(id,page))
  //     // setSetEntidades(entidades.concat(entidadesComerciales))
  //   }

  // }, [page])



  const getPage = (page) => {
    setPage(page)
    //console.log('Page del padre: ', page)
  }

  const handleButtonClick = async () => {
    try {
      const objetoJSON = {
        estado: 5
      };

      console.log(objetoJSON);

      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      };

      const pago = await axios.post(
        baseUrl + `pagar/anticipos/${user?.user_tarjeta[0]?.id}`,
        JSON.stringify(objetoJSON),
        { headers }
      );
      if (pago.status === 201) {
        console.log("Pago realizado con éxito", pago);
        Swal.fire({
          icon: "success",
          title: "Anticipo acreditado con éxito",
          text: "",
          confirmButtonText: "Aceptar",
        });
      }
    } catch (error) {
      console.error("Error gestionando el anticipo:", error);
    }
  };

  return (
    <SimpleCard
      title={
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <h2 style={{ margin: 0 }}>Anticipos</h2>
          <Button
            variant="contained"
            color="primary"
            onClick={handleButtonClick} // Reemplaza con tu función de manejo de clic
            style={{ marginLeft: 'auto' }}
          >
            Pagar Anticipos
          </Button>
        </div>
      }
    >
      {(accesosUser === undefined)
        ? <Alert severity="info">No hay registro de anticipos.</Alert>
        : <AccesosTableModal
          listaEntidades={accesosUser}
          getPage={getPage}
          totalRegs={accesosUser.length}
          user={user}
        />}
    </SimpleCard>

  )
}

export default ListaAnticiposUsuario