import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
} from "@mui/material";
import { SimpleCard } from "../../../compontents/SimpleCard";
import { Alert } from "@mui/lab";
import { UserTarjetaTable } from "../../Negocios/UserTarjetaTable";

const Listado = ({ user }) => {
  console.log(user);
  const [role, setRole] = useState(user.rol);
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const [tarjetas, setTarjetas] = React.useState([]);
  const [busqueda, setBusqueda] = useState("");
  const [paginaActual, setPaginaActual] = React.useState(1);
  const [filtrando, setFiltrando] = useState(false);
  const elementosPorPagina = 5;
  const numerosPaginaMostrados = 4;
  const [tipoFiltro, setTipoFiltro] = useState("todos"); // Por defecto, mostrará todos los resultados
  const [page, setPage] = useState(0);
  const [totalRegs, setTotalRegs] = useState(1);
  const [configuracion, setConfiguracion] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(baseUrl + "usuario/tarjeta/listar");
        let datos = response.data.data;
        let datosJSON = JSON.stringify(datos);
        localStorage.setItem("allCards", datosJSON);
        console.log(datos);
        setTarjetas(datos);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchConfiguracion = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        };
        const { data } = await axios.post(
          baseUrl + "configuracion/puntos/listar",
          null,
          config
        );
        console.log(data);
        setConfiguracion(data.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchConfiguracion();
  }, []);

  // Función de filtro para buscar por ID de tarjeta y nombre
  const filteredTarjetas = tarjetas.filter((tarjeta) => {
    const nombre = tarjeta.nombre ? tarjeta.nombre.toLowerCase() : "";
    const id = tarjeta.id ? tarjeta.id.toLowerCase() : "";
    const esPagable =
      tarjeta.informacion_bancaria &&
      parseInt(tarjeta.clic_cobrar) * 5 +
        parseInt(tarjeta.efectivo_reviews) * 10 +
        parseInt(tarjeta.efectivo_menu) >
        0;
    const esCanjeable = tarjeta.wallet > 0;

    if (tipoFiltro === "pagables" && filtrando) {
      return (
        (nombre.includes(busqueda.toLowerCase()) ||
          id.includes(busqueda.toLowerCase())) &&
        esPagable
      );
    } else if (tipoFiltro === "canjeables" && filtrando) {
      return (
        (nombre.includes(busqueda.toLowerCase()) ||
          id.includes(busqueda.toLowerCase())) &&
        esCanjeable
      );
    } else {
      // Si no hay filtro aplicado, mostrar todos los resultados
      return (
        nombre.includes(busqueda.toLowerCase()) ||
        id.includes(busqueda.toLowerCase())
      );
    }
  });

  // Función para manejar cambios en la búsqueda
  const handleChangeBusqueda = (event) => {
    setBusqueda(event.target.value);
    setPaginaActual(1); // Reiniciar la página actual a la primera página
  };

  const totalPages = Math.ceil(tarjetas.length / elementosPorPagina);
  const indiceInicioNumerosPagina = Math.max(paginaActual - 1, 0);
  const numerosPagina = Array.from(
    { length: numerosPaginaMostrados },
    (_, index) => index + indiceInicioNumerosPagina + 1
  );

  // Función para alternar el estado de filtrado
  const toggleFiltrado = (filtro) => {
    setTipoFiltro(filtro);
    setFiltrando(!filtrando);
  };

  const getPage = (page) => {
    setPage(page);
    //console.log('Page del padre: ', page)
  };

  const obtenerTarjetasPaginadas = () => {
    const indiceInicio = (paginaActual - 1) * elementosPorPagina;
    const indiceFin = indiceInicio + elementosPorPagina;
    return filteredTarjetas.slice(indiceInicio, indiceFin);
  };

  console.log(obtenerTarjetasPaginadas());

  const actualizarPago = (idTarjeta, clicCobrar) => {
    console.log(idTarjeta, clicCobrar);
    const nuevasTarjetas = tarjetas.map((tarjeta) => {
      if (tarjeta.id === idTarjeta) {
        return {
          ...tarjeta,
          clic_cobrar: 0,
          clic_pagados:
            parseInt(tarjeta.clic_pagados) + parseInt(tarjeta.clicCobrar),
          informacion_bancaria: "",
          efectivo_menu: 0,
          efectivo_reviews: 0,
        };
      }
      return tarjeta;
    });
    setTarjetas(nuevasTarjetas);
  };

  const actualizarEfectivo = (idTarjeta, nuevoEstado, campoCobrar) => {
    console.log(idTarjeta, nuevoEstado);
    const nuevasTarjetas = tarjetas.map((tarjeta) => {
      if (tarjeta.id === idTarjeta) {
        return {
          ...tarjeta,
          [campoCobrar]: nuevoEstado,
        };
      }
      return tarjeta;
    });
    setTarjetas(nuevasTarjetas);
  };

  return (
    <>
      <Grid container spacing={2}>
        {role === "ADMIN" ? (
          <Grid item xs={12} sm={6}>
            {/* Botón para alternar el estado de filtrado */}
            <Button
              onClick={() => toggleFiltrado("pagables")}
              variant="contained"
              // endIcon={<AttachMoneyIcon />}
              sx={{
                width: "100%",
                marginBottom: "8px",
                margin: "9px 0px",
              }} // Añadimos un margen inferior de 8px
            >
              {filtrando ? "Mostrar todos" : "Mostrar solo pagables"}
            </Button>
          </Grid>
        ) : role === "CAJERO" ||
          role === "PROPIETARIO" ||
          role === "GERENTE" ? (
          <Grid item xs={12} sm={6}>
            {/* Botón para alternar el estado de filtrado */}
            <Button
              onClick={() => toggleFiltrado("canjeables")}
              variant="contained"
              // endIcon={<AttachMoneyIcon />}
              sx={{
                width: "100%",
                marginBottom: "8px",
                margin: "9px 0px",
              }} // Añadimos un margen inferior de 8px
            >
              {filtrando ? "Mostrar todos" : "Mostrar solo canjeables"}
            </Button>
          </Grid>
        ) : (
          <></>
        )}
        <Grid item xs={12} sm={6}>
          <TextField
            label="Buscar"
            variant="outlined"
            value={busqueda}
            onChange={handleChangeBusqueda}
            style={{ marginBottom: "10px" }}
            sx={{ width: "100%" }}
          />
        </Grid>
      </Grid>
      <SimpleCard title={"Tarjetas de usuario"}>
        {obtenerTarjetasPaginadas().length === 0 ? (
          <Alert severity="info">No se han registrado tarjetas</Alert>
        ) : (
          <UserTarjetaTable
            listaEntidades={obtenerTarjetasPaginadas()}
            getPage={getPage}
            totalRegs={totalRegs}
            role={role}
            puntos={configuracion[0]?.cantidad_puntos}
            actualizarEfectivo={actualizarEfectivo}
            actualizarPago={actualizarPago}
          />
        )}
      </SimpleCard>
    </>
  );
};

export default Listado;
