import { LinearProgress, Box, Button, Grid, styled, Icon } from "@mui/material";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiDialogActions from "@mui/material/DialogActions";
import React, { useEffect, useState } from "react";
import ListaAnticiposUsuario from "../accesos/ListaAnticiposUsuario";

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const UserInfo = styled("div")(({ theme }) => ({
  paddingRight: "33px",
  paddingLeft: "33px",
  paddingTop: "0",
  paddingBottom: "0",
  textAlign: "center",
}));

const Parrafo = styled("h5")(({ theme }) => ({
  padding: "0px",
  color: "#ffffff",
  fontSize: "16px",
  fontWeight: "normal",
  marginTop: "10px",
  marginBottom: "10px",
}));

const Titulo = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#053D6B",
}));

const GridContainer = styled(Grid)(() => ({
  display: "flex",
  alignContent: "start",
  alignItems: "center",
  borderBottom: "2px solid #DCDCDC",
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  "&.root": { margin: 0, padding: theme.spacing(1) },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

export default function ModalVerAdelantos(usuario) {

  const [estilo, setEstilo] = useState(null);
  const [anticipos, setAnticipos] = useState([]);
  const { user } = usuario;
  const tarjetaId = user?.user_tarjeta[0]?.id;

  console.log("user,contact", user)

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    // console.log(interfaz)
    if (localStorage.getItem("interfaz")) {
      // console.log(JSON.parse(localStorage.getItem("interfaz")))
      const slug = JSON.parse(localStorage.getItem("interfaz"));
      setEstilo(slug);
    }
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(`${baseUrl}pagos/tarjeta/${tarjetaId}`);
        if (data && data.data) {
          console.log(data.data);
          setAnticipos(data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [tarjetaId]);

  return (
    <Box>
      <StyledButton
        variant="outlined"
        type="submit"
        color="primary"
        onClick={handleClickOpen}
        sx={{
          width: "max-content",
          fontWeight: "bold",
          border: "2px solid",
          color: !estilo ? "#437294" : estilo.color_principal,
          borderRadius: "10px",
        }}
      >
        Ver anticipos
      </StyledButton>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Titulo
          id="responsive-dialog-title"
          sx={{ background: !estilo ? "#053D6B" : estilo.color_gradient }}
        >
          <IconButton
            aria-label="Close"
            className="closeButton"
            onClick={handleClose}
            sx={{
              color: theme.palette.grey[500],
              position: "absolute",
              right: theme.spacing(1),
              top: theme.spacing(1),
            }}
          >
            <CloseIcon />
          </IconButton>
          <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
            <UserInfo>
              <Grid
                container
                sx={{ paddingRight: "30px", paddingLeft: "30px" }}
              >
                <GridContainer container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Parrafo sx={{ color: !estilo ? "#ffffff" : "#000000" }}>
                      {user.nombres} {user.apellidos}
                    </Parrafo>
                  </Grid>
                </GridContainer>
              </Grid>
            </UserInfo>
          </Grid>
        </Titulo>

        <LinearProgress value={0} variant="determinate" className="progress" />

        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
          <ListaAnticiposUsuario anticipos={anticipos} user={user} />
        </Grid>
      </Dialog>
    </Box>
  );
}
