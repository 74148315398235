import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AuthService from "../../.././auth-service";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import logo from "../../../assets/img/favicon.ico";

import { uploadFileToFBbdd } from "../../../firebaseConfig";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
const theme = createTheme();

export default function SignUp() {
  const [portada, setPortada] = useState("");
  const [perfil, setPerfil] = useState("");
  const name = localStorage.getItem("Nombre");
  const profesion = localStorage.getItem("Profesion");
  const descripcion = localStorage.getItem("Descripcion");
  const telefono = localStorage.getItem("Phone");
  const direccion = localStorage.getItem("Ubicacion");
  const correo = localStorage.getItem("Mail");
  const web = localStorage.getItem("Url");
  const titulo = localStorage.getItem("Titulo");
  const twitter = localStorage.getItem("Twitter");
  const facebook = localStorage.getItem("Facebook");
  const instagram = localStorage.getItem("Instagram");
  const linkedin = localStorage.getItem("Linkedin");
  const youtube = localStorage.getItem("Youtube");
  const tiktok = localStorage.getItem("Tiktok");
  const telegram = localStorage.getItem("Telegram");
  const whatsapp = localStorage.getItem("Whatsapp");
  const [tieneTarjeta, setTieneTarjeta] = useState("tiene");
  const [password, setPassword] = useState("Onlytap2024");
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [codigo, setCodigo] = useState("");

  const [nameF, setNameF] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [email, setEmail] = useState("");

  const baseUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchImageURL = async () => {
      const newestImageURL = localStorage.getItem("FotoPortada");
      const newestImagePerfilURL = localStorage.getItem("FotoPerfil");
      setPortada(newestImageURL);
      setPerfil(newestImagePerfilURL);

      await uploadFileToFBbdd(newestImageURL, "ActivoPortada");
      await uploadFileToFBbdd(newestImagePerfilURL, "ActivoPerfil");
    };
    fetchImageURL();
  }, []);

  const [formData, setFormData] = useState({
    name: name,
    rol: "USER",
    registerBy: "WEB",
    profesion: profesion,
    empresa: descripcion,
    telefono: telefono,
    direccion: direccion,
    correo: correo,
    web: web,
    twitter: twitter,
  });

  useEffect(() => {
    if (correo) {
      // console.log(correo);
      setEmail(correo);
    }
  }, [correo]);

  useEffect(() => {
    if (tieneTarjeta === "notiene") {
      setError(null);
      setCodigo("");
    }
  }, [tieneTarjeta]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    handleSignup();
  };

  const handleSignup = async (e) => {
    // Validar campos vacios
    if (nameF === "") {
      setError("Por favor ingrese su nombre y apellido");
      handleClose();
      return;
    }
    if (email === "") {
      setError("Por favor ingrese su correo electrónico");
      handleClose();
      return;
    }
    if (phone === "") {
      setError("Por favor ingrese su teléfono");
      handleClose();
      return;
    }
    if (tieneTarjeta === "tiene" && codigo === "") {
      setError("Por favor ingrese el código de su sobre");
      handleClose();
      return;
    }
    // condicion para validar que el usuario no tiene codigo de tarjeta
    if (!codigo) {
      const tiktokup = JSON.parse(tiktok);
      const twitterup = JSON.parse(twitter);
      const facebookup = JSON.parse(facebook);
      const youtubeup = JSON.parse(youtube);
      const linkedinup = JSON.parse(linkedin);
      const instagramup = JSON.parse(instagram);
      let myArray = [
        twitterup,
        facebookup,
        youtubeup,
        tiktokup,
        linkedinup,
        instagramup,
      ];
      myArray = myArray.filter((value) => value !== null);
      const redesSociales = myArray.map((value, index) => {
        const socialName = value.tipo_social
          .match(/\/\/(?:www\.)?([^]+)/)[1]
          .split(".")[0];
        return {
          id: socialName, // Puedes utilizar el índice del elemento más 1 como identificador
          value: value.tipo_social,
        };
      });

      try {
        // console.log(formData);
        // Separar nombre y apellido
        let nombre = "";
        let apellido = "";
        if (formData.name) {
          const parts = formData.name.split(" ");
          if (parts.length === 1) {
            nombre = parts[0];
          } else if (parts.length === 2) {
            nombre = parts[0];
            apellido = parts[1];
          } else if (parts.length === 3) {
            nombre = `${parts[0]} ${parts[1]}`;
            apellido = parts[2];
          } else if (parts.length === 4) {
            nombre = `${parts[0]} ${parts[1]}`;
            apellido = `${parts[2]} ${parts[3]}`;
          }
        }
        // Peticion de registro
        const signupResponse = await AuthService.signup(
          apellido,
          email,
          nombre,
          password,
          formData.rol,
          formData.registerBy
        );
        // console.log(signupResponse);
        const userID = signupResponse.data.id;
        // Peticion de login y almacenamiento de user en localstorage
        const loginResponse = await AuthService.login(email, password);
        const token = loginResponse.authorisation.token;
        // console.log(signupResponse.data);
        // Guardar token  en localstorage
        localStorage.setItem("token", token);
        // Peticion de creacion de tarjeta
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        };
        const cardCreateResponse = await axios.post(
          baseUrl + "usuario/tarjeta/crear",
          {
            usuario_id: userID,
            img_portada: portada,
            img_perfil: perfil,
            nombre: formData.name,
            profesion: formData.profesion,
            empresa: formData.empresa,
            acreditaciones: "",
            telefono: formData.telefono,
            direccion: formData.direccion,
            correo: formData.correo,
            sitio_web: formData.web,
            titulo: titulo,
            estado: 1,
            whatsapp: whatsapp,
            telegram: telegram,
          },
          { headers }
        );
        // console.log(cardCreateResponse.data.data.id);
        const tarjetaId = cardCreateResponse.data.data.id;
        // Peticiones de creacion de redes sociales
        for (const redSocial of redesSociales) {
          try {
            const socialCreateResponse = await axios.post(
              baseUrl + "tarjeta/sociales/crear",
              {
                user_tarjeta_id: tarjetaId,
                text_label: redSocial.id,
                tipo_social: redSocial.value,
                icon_social: "URL",
              },
              { headers }
            );

            // console.log(
            //   "Usuario y tarjeta creada correctamente" +
            //     socialCreateResponse.data
            // );
          } catch (error) {
            // console.log("Error al crear las redes sociales");
            handleClose();
          }
        }
        // limpiar localstorage
        localStorage.clear();
        localStorage.setItem("tarjetaID", cardCreateResponse.data.data.id);
        //login de usuario actualizado con tarjeta y sus redes sociales, almacenamiento de user en localstorage
        const loginNew = await AuthService.login(email, password);
        // Guardar token en localstorage
        const tokenNew = loginNew.authorisation.token;
        localStorage.setItem("token", tokenNew);
        window.location.href = "/OnlyTap";
      } catch (error) {
        console.log(error);
        setError(
          error.response.data.data.correo + " Por favor intente con otro correo"
        );
        handleClose();
      }
    } else {
      // condicion para validar que el usuario si tiene codigo de tarjeta
      const tiktokup = JSON.parse(tiktok);
      const twitterup = JSON.parse(twitter);
      const youtubeup = JSON.parse(youtube);
      const linkedinup = JSON.parse(linkedin);
      const instagramup = JSON.parse(instagram);
      const facebookup = JSON.parse(facebook);
      let myArray = [
        twitterup,
        facebookup,
        youtubeup,
        tiktokup,
        linkedinup,
        instagramup,
      ];
      myArray = myArray.filter((value) => value !== null);
      const redesSociales = myArray.map((value, index) => {
        const socialName = value.tipo_social
          .match(/\/\/(?:www\.)?([^]+)/)[1]
          .split(".")[0];
        return {
          id: socialName, // Puedes utilizar el índice del elemento más 1 como identificador
          value: value.tipo_social,
        };
      });

      try {
        // petición para verificar si el codigo de tarjeta existe
        const consultarId = await axios.post(
          baseUrl + `verificar-id/${codigo}`
        );
        // verificar si el codigo de tarjeta existe
        if (consultarId.data.existe) {
          // console.log("hola");
          // Separar nombre y apellido
          let nombre = "";
          let apellido = "";
          if (nameF) {
            const parts = nameF.split(" ");
            if (parts.length === 1) {
              nombre = parts[0];
            } else if (parts.length === 2) {
              nombre = parts[0];
              apellido = parts[1];
            } else if (parts.length === 3) {
              nombre = `${parts[0]} ${parts[1]}`;
              apellido = parts[2];
            } else if (parts.length === 4) {
              nombre = `${parts[0]} ${parts[1]}`;
              apellido = `${parts[2]} ${parts[3]}`;
            }
          }
          console.log("apellido", apellido);
          console.log("email", email);
          console.log("nombre", nombre);
          console.log("password", password);
          console.log("formData.rol", formData.rol);
          console.log("formData.registerBy", formData.registerBy);

          //peticion de registro
          const register = await AuthService.signup(
            apellido,
            email,
            nombre,
            password,
            formData.rol,
            formData.registerBy
          );
          // console.log(register);
          const userId = register.data.id;
          // peticion de login y almacenamiento de user en localstorage
          const loginResponse = await AuthService.login(email, password);
          const token = loginResponse.authorisation.token;
          // console.log(loginResponse);
          // Guardar token en localstorage
          const TarjetaID = codigo;
          localStorage.setItem("token", token);
          // peticion de actualizacion de tarjeta
          const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          };
          const updatedFormData = {
            usuario_id: userId,
            img_portada: portada,
            img_perfil: perfil,
            nombre: nameF,
            profesion: formData.profesion,
            empresa: formData.empresa,
            acreditaciones: "",
            telefono: phone,
            direccion: formData.direccion,
            correo: email,
            sitio_web: formData.web,
            titulo: titulo,
            estado: 1,
            whatsapp: whatsapp,
            telegram: telegram,
          };
          const cardCreateResponse = await axios.post(
            baseUrl + `usuario/tarjeta/actualizar/${TarjetaID}`,
            updatedFormData,
            { headers }
          );
          // console.log(cardCreateResponse.data.data.id);
          // console.log(cardCreateResponse);
          // peticiones de creacion de redes sociales
          for (const redSocial of redesSociales) {
            try {
              const socialCreateResponse = await axios.post(
                baseUrl + "tarjeta/sociales/crear",
                {
                  user_tarjeta_id: TarjetaID,
                  text_label: redSocial.id,
                  tipo_social: redSocial.value,
                  icon_social: "URL",
                },
                { headers }
              );

              // console.log(
              //   "Red Social creada correctamente" + socialCreateResponse.data
              // );
            } catch (error) {
              // console.log("Error al crear las redes sociales");
              handleClose();
            }
          }
          // limpiar localstorage
          localStorage.clear();
          localStorage.setItem("tarjetaID", cardCreateResponse.data.data.id);
          //login de usuario actualizado con tarjeta y sus redes sociales, almacenamiento de user en localstorage
          const loginFinal = await AuthService.login(email, password);
          // Guardar token en localstorage
          const tokenFinal = loginFinal.authorisation.token;
          localStorage.setItem("token", tokenFinal);
          window.location.href = "/OnlyTap";
        } else {
          // codigo de tarjeta no existe
          setError(
            "El codigo ingresado no existe en la plataforma, por favor intente de nuevo"
          );
          handleClose();
        }
      } catch (error) {
        console.log(error);
        setError(
          "Credenciales incorrectas o codigo de tarjeta incorrecto, por favor intente de nuevo"
        );
        handleClose();
      }
    }
  };

  const handleEmailChange = (event) => {
    const newMail = event.target.value;
    localStorage.setItem("Mail", newMail);
    window.dispatchEvent(new Event("storage"));
    setEmail(newMail);
    if (!/\S+@\S+\.\S+/.test(event.target.value)) {
      setEmailError("El correo electrónico no es válido");
    } else {
      setEmailError("");
    }
  };

  const handleNameChange = (event) => {
    const newNombre = event.target.value;
    localStorage.setItem("Nombre", newNombre);
    window.dispatchEvent(new Event("storage"));
    setNameF(newNombre);
  };

  const handlePhoneChange = (event) => {
    const newPhone = event.target.value;
    localStorage.setItem("Phone", newPhone);
    window.dispatchEvent(new Event("storage"));
    setPhone(newPhone);
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Card>
            <CardMedia
              component="img"
              height="140"
              image={logo}
              alt="Descripción de la imagen"
            />
          </Card>
          <Typography component="h1" variant="h5">
            Registra tu sobre
          </Typography>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Nombre y Apellido"
                value={name}
                onChange={handleNameChange}
                placeholder="Ingrese su nombre y apellido"
                helperText="Requerido"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Correo Electrónico"
                value={email}
                onChange={handleEmailChange}
                placeholder="Ingrese su correo electrónico"
                helperText="Requerido"
                type="email"
                autoComplete="email"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Celular"
                value={phone}
                onChange={handlePhoneChange}
                placeholder="Ingrese su número de celular"
                helperText="Requerido"
                type="tel" // Use "tel" input type for phone numbers
              />
            </Grid>
            {/* Add any additional form fields or submit button here */}
          </Grid>

          <br />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="codigo"
                label="Codigo de sobre"
                name="codigo"
                autoComplete="codigo"
                onChange={(event) => setCodigo(event.target.value)}
                value={codigo}
                disabled={tieneTarjeta === "notiene"}
                helperText="Requerido"
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "0px",
              }}
            >
              <Button
                type="button"
                onClick={handleOpen}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                REGISTRAR SOBRE
              </Button>
              {/* <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="tiene"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="tiene"
                  control={<Radio />}
                  label="Tengo una tarjeta"
                  onChange={(event) => setTieneTarjeta(event.target.value)}
                />
                <FormControlLabel
                  value="notiene"
                  control={<Radio />}
                  label="NO tengo tarjeta"
                  onChange={(event) => setTieneTarjeta(event.target.value)}
                />
              </RadioGroup> */}
            </Grid>
          </Grid>
          <div>

            <Backdrop
              sx={{
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={open}
              onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            {error && (
              <div style={{ color: "red", marginTop: "10px" }}>{error}</div>
            )}
          </div>

          {/* <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/" variant="body2">
                Ya tienes una cuenta? Inicia Sesion
              </Link>
            </Grid>
          </Grid> */}
        </Box>
      </Container>
    </ThemeProvider>
  );
}
