import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";

const SmallEstadoActivo = styled(Button)(({ activo }) => ({
  height: 30,
  width: "max-content",
  alignSelf: "center",
  fontSize: 20,
  fontWeight: "bold",
  color: activo ? "#84CDA4" : "#F16360",
  padding: "2px 8px",
  borderRadius: "4px",
  overflow: "hidden",
  background: activo ? "#DAF4E9" : "#FDEDED",
  boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)",
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CambiarEstadoEntidadDialog = ({
  estadoEntidad,
  getEstadoEntidad,
}) => {
  const [open, setOpen] = React.useState(false);
  const [estado, setEstado] = useState(estadoEntidad);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  const handleEstadoChange = () => {
    setEstado(!estado);
    handleClose();
  };

  useEffect(() => {
    getEstadoEntidad(estado);
  }, [estado]);

  return (
    <div>
      <SmallEstadoActivo
        activo={estado}
        onClick={handleClickOpen}
        // disabled={!disabledButton}
      >
        {estado ? "Local" : "Global"}
      </SmallEstadoActivo>
      <Dialog
        // open={open}
        keepMounted
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Cambiar estado de la entidad
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {`Al cambiar el estado de la entidad se eliminará el registro.  
            ¿Desea cambiar de ${estado ? "activo" : "inactivo"} a ${
              !estado ? "activo" : "inactivo"
            }?`}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancelar
          </Button>
          <Button
            onClick={handleEstadoChange}
            color="primary"
            variant="contained"
          >
            Cambiar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
