import React, { useEffect, useState } from "react";
import Form from "./promocionesForm";
import axios from "axios";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { ModalVerPuntos } from "./modals/index";
import { styled, Box, Icon, Menu } from "@mui/material";
import MuiDialogActions from "@mui/material/DialogActions";
import ModalDesactivar from "../../Usuarios/ModalDesactivar";
import calendarImage from "./calendario.jpg";

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  "&.root": { margin: 0, padding: theme.spacing(1) },
}));

const styleContainer = {
  display: "flex",
  justifyContent: "space-between",
  padding: "0px 10px 0px 10px",
  backgroundColor: "#DDDDDD", // rojo claro
};

const styleGanados = {
  backgroundColor: "#d4edda", // verde claro
  padding: "10px",
  borderRadius: "5px",
  margin: "5px",
};

const styleCanjeados = {
  backgroundColor: "#D3D3D3", // rojo claro
  padding: "10px",
  borderRadius: "5px",
  margin: "5px",
};

const styleDisponibles = {
  backgroundColor: "#ffffff", // amarillo claro
  padding: "10px",
  borderRadius: "5px",
  margin: "5px",
};

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

function Reservas({ rol, entidad }) {
  const baseUrl = process.env.REACT_APP_BACKEND_URL;
  const [tarjeta, setTarjeta] = useState(null);
  const [tarjetas, setTarjetas] = React.useState([]);
  const [locales, setLocales] = React.useState([]);
  const [globales, setGlobales] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [ShowModalVerPuntos, setShowModalVerPuntos] = useState(false);
  const [anchorE, setAnchorE] = React.useState(null);
  const [listPuntos, setListPuntos] = useState([]);
  const [estilo, setEstilo] = useState(null);
  const [configuracion, setConfiguracion] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isPuntosOpen, setIsPuntosOpen] = useState(true);
  const [isPromocionesOpen, setIsPromocionesOpen] = useState(false);

  const togglePuntos = () => {
    setIsPuntosOpen(!isPuntosOpen);
  };

  const togglePromociones = () => {
    setIsPromocionesOpen(!isPromocionesOpen);
  };

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  const handleAbrir = () => {
    setOpen(!open);
  };

  const handleEdit = () => {};

  useEffect(() => {
    const tarjetaGuardada = JSON.parse(localStorage.getItem("Tarjeta"));
    console.log(tarjetaGuardada?.wallet);
    setTarjeta(tarjetaGuardada?.wallet);
  }, []);

  const [sumaPuntos, setSumaPuntos] = useState(0);
  const [sumaEstadoCero, setSumaEstadoCero] = useState(0);
  const [sumaEstadoUno, setSumaEstadoUno] = useState(0);

  useEffect(() => {
    console.log(tarjeta);
    if (tarjeta) {
      const { sumaCero, sumaUno } = tarjeta.reduce(
        (acc, pago) => {
          if (parseInt(pago.estado) === 0) {
            acc.sumaCero += parseFloat(pago.total_puntos);
          } else if (parseInt(pago.estado) === 2) {
            acc.sumaUno += parseFloat(pago.total_puntos);
          }
          return acc;
        },
        { sumaCero: 0, sumaUno: 0 }
      );
      setSumaPuntos(sumaCero - sumaUno);
      setSumaEstadoCero(sumaCero);
      setSumaEstadoUno(sumaUno);
    }
  }, [tarjeta]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        };
        const response = await axios.post(
          baseUrl + "promociones/fidelizacion/listar",
          {},
          { headers }
        );
        let datos = response.data.data;
        let datosJSON = JSON.stringify(datos);
        localStorage.setItem("allProms", datosJSON);
        // Filtramos por tipo LOCALES y GLOBALES
        const locales = datos.filter((item) => item.tipo === "LOCALES");
        const globales = datos.filter((item) => item.tipo === "GLOBALES");

        // Guardamos en los estados correspondientes
        setLocales(locales);
        setGlobales(globales);

        // También puedes seguir utilizando setTarjetas si es necesario
        setTarjetas(datos);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchConfiguracion = async () => {
      try {
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        };
        const { data } = await axios.post(
          baseUrl + "configuracion/puntos/listar",
          null,
          config
        );
        console.log(data);
        setConfiguracion(data.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchConfiguracion();
  }, []);

  const handleVerWallet = (pagos) => {
    console.log(pagos);
    setAnchorE(null);
    setListPuntos(pagos);
    setShowModalVerPuntos(true);
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {(rol === "ADMIN" || rol === "PROPIETARIO" || rol === "GERENTE") && (
          <>
            <Button
              onClick={handleAbrir}
              variant="contained"
              sx={{
                width: "30%",
                marginBottom: "10px",
                margin: "9px 0px",
              }}
            >
              Crear
            </Button>
          </>
        )}
      </div>
      {open && <Form entidad={entidad} />}
      <div
        className=""
        style={{
          borderRadius: "10px",
          border: "1px solid #E0E0E0",
          padding: "20px",
          backgroundColor: "#DDDDDD",
        }}
      >
        <div style={{ cursor: "pointer" }} onClick={togglePuntos}>
          <div style={styleContainer}>
            <h2>Reservas</h2>
          </div>
        </div>
        {isPuntosOpen && (
          <div
            className=""
            style={{
              borderRadius: "10px",
              border: "1px solid #E0E0E0",
              padding: "20px",
              backgroundColor: "white",
              display: "flex", // Usar flexbox
              justifyContent: "center", // Centrado horizontal
              alignItems: "center", // Centrado vertical
              height: "600px", // Aumentar el tamaño del contenedor para hacerla más grande
            }}
          >
            {/* Mostrar la imagen importada */}
            <img
              src={calendarImage}
              alt="Calendario"
              style={{
                maxWidth: "100%", // Ajustar el tamaño de la imagen
                maxHeight: "100%", // Ajustar la altura de la imagen
              }}
            />
          </div>
        )}
      </div>
      <ModalVerPuntos
        isVisible={ShowModalVerPuntos}
        onClose={() => setShowModalVerPuntos(false)}
        pagos={listPuntos}
        puntosG={configuracion[0]?.cantidad_puntos}
      />
    </div>
  );
}

export default Reservas;
