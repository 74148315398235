import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Table,
  TableBody,
  Box,
  Paper,
  TableContainer,
  Grid,
  Icon,
  Skeleton,
  Button,
  styled,
} from "@mui/material";
import { esES } from "@mui/material/locale";
import { EnhancedTableHead } from "../../compontents/EnhancedTableHead";
import { PaginatorServer } from "../../compontents/Paginator";
import { usePagination } from "../../compontents/usePagination";
import {
  getComparator,
  StyledTableCell,
  StyledTableRow,
  Container,
  stableSort,
} from "../../compontents/TableFunctions";
import { ModalAceptarAnticipo } from "../Dashboard/Components/modals";
import { ModalNegarAnticipo } from "../Dashboard/Components/modals";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import AuthService from "../../auth-service";

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));
/**
 * Cabecera de la tabla
 */

const headCells = [
  {
    id: "fecha",
    numeric: false,
    disablePadding: false,
    label: "Fecha",
  },
  {
    id: "cantidad",
    numeric: false,
    disablePadding: false,
    label: "Cantidad",
  },
  {
    id: "detalle",
    numeric: false,
    disablePadding: false,
    label: "Motivo",
  },
  {
    id: "accion",
    numeric: false,
    disablePadding: false,
    label: "Acción",
  },
];

const theme = createTheme(esES);

/**
 *
 * @param  listaEntidades los nuevos registros que vienen de 10en 10 de la paginación
 * @param  getPage función para obtener la pagina que se consulta
 * @param  totalRegs el total de registros que trae la consulta a la api
 * @returns  el componente tabla
 */

export const AccesosTableModal = ({
  listaEntidades = [],
  getPage,
  totalRegs,
  user
}) => {
  const usuario = AuthService.getCurrentUser().authorisation.data;
  console.log(listaEntidades, usuario);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [pagarTarjeta, setPagarTarjeta] = useState(null);
  const [negar, setNegar] = useState(null);
  const [ShowModalPagar, setShowModalPagar] = useState(false);
  const [ShowModalNegar, setShowNegar] = useState(false);

  const { page, handleChangePage, loading, registros } = usePagination(
    listaEntidades,
    totalRegs,
    getPage
  );

  // console.log("lista", listaEntidades)

  const handleRequestSort = (event, property) => {
    // console.log('handleRequestSort called')
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    // setPage(0);
  };

  const skeletonArray = Array(10).fill("");

  const setFecha = (fecha) => {
    const fechaActual = new Date(fecha);
    return fechaActual.toLocaleString();
  };

  const handleAnticipar = (row) => {
    // setAnchorEl(null);
    setPagarTarjeta(row);
    setShowModalPagar(true);
  };

  const handleNegar = (row) => {
    // setAnchor(null);
    setNegar(row);
    setShowNegar(true);
  }

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Box with="100%" overflow="auto">
              <ThemeProvider theme={theme}>
                <Paper sx={{ with: "100%", overflow: "scroll" }}>
                  <TableContainer sx={{ maxHeight: "100%" }}>
                    <Table
                      sx={{ minWidth: 500 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={registros.length}
                        headCells={headCells}
                      />

                      <TableBody>
                        {loading &&
                          skeletonArray.map((item, index) => (
                            <StyledTableRow key={index}>
                              {headCells.map((head) => (
                                <StyledTableCell key={head.id}>
                                  <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={20}
                                  />
                                </StyledTableCell>
                              ))}
                            </StyledTableRow>
                          ))}
                        {!loading &&
                          stableSort(registros, getComparator(order, orderBy))
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, i) => (
                              <StyledTableRow
                                key={row.id}
                                style={{ backgroundColor: Number(row.estado) === 3 ? '#d4edda' : Number(row.estado) === 4 ? '#f8d7da' : 'inherit' }}
                              >
                                <StyledTableCell align="left">
                                  {/* {setFecha(Number(row.estado) === 3 || Number(row.estado) === 4 ? row.updated_at : row.created_at)} */}
                                  {setFecha(row.created_at)}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  $ {row.cantidad}
                                </StyledTableCell>
                                <StyledTableCell align="left">
                                  {row.detalle}
                                </StyledTableCell>
                                {usuario.rol === "GERENTE" && Number(row.estado) === 2 ? <StyledTableCell align="left">
                                  <StyledButton
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={() =>
                                      handleAnticipar(row)
                                    }
                                    sx={{
                                      color: "#ffffff",
                                      width: "max-content",
                                      background: "#053D6B",
                                      borderRadius: "10px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Aceptar
                                  </StyledButton>
                                  <StyledButton
                                    variant="outlined"
                                    type="submit"
                                    color="secondary"
                                    onClick={() =>
                                      handleNegar(row)
                                    }
                                    sx={{
                                      width: "max-content",
                                      fontWeight: "bold",
                                      border: "2px solid #DA4040",
                                      borderRadius: "10px",
                                      color: "crimson",
                                    }}
                                  >
                                    Negar
                                  </StyledButton>
                                </StyledTableCell> : Number(row.estado) === 3 ? <Tooltip
                                  title={
                                    <Typography style={{ fontSize: "15px", whiteSpace: "pre-line" }}>
                                      {`Fecha: ${setFecha(row.updated_at)}
                                      Entidad: ${JSON.parse(row.informacion_bancaria).entidad}
                                      Tipo de Cuenta: ${JSON.parse(row.informacion_bancaria).tipoCuenta}
                                      Identificación: ${JSON.parse(row.informacion_bancaria).identificacion}
                                      Número de Cuenta: ${JSON.parse(row.informacion_bancaria).numeroCuenta}`}
                                    </Typography>
                                  }
                                  arrow
                                >
                                  <button
                                    style={{
                                      background: "transparent",
                                      color: "#554CE5",
                                      cursor: "pointer",
                                      position: "relative",
                                      borderRadius: "50%",
                                      padding: "1px 9px 1px 9px",
                                      margin: "6px 0px 0px 6px",
                                    }}
                                  >
                                    APROBADO
                                  </button>
                                </Tooltip> : Number(row.estado) === 4 ? <Tooltip
                                  title={
                                    <Typography style={{ fontSize: "15px", whiteSpace: "pre-line" }}>
                                      {`Fecha: ${setFecha(row.updated_at)}
                                      Motivo: ${row.motivo}`}
                                    </Typography>
                                  }
                                  arrow
                                >
                                  <button
                                    style={{
                                      background: "transparent",
                                      color: "#554CE5",
                                      cursor: "pointer",
                                      position: "relative",
                                      borderRadius: "50%",
                                      padding: "1px 9px 1px 9px",
                                      margin: "6px 0px 0px 6px",
                                    }}
                                  >
                                    NEGADO
                                  </button>
                                </Tooltip> : Number(row.estado) === 5 ? <Tooltip
                                  title={
                                    <Typography style={{ fontSize: "15px", whiteSpace: "pre-line" }}>
                                      {`Fecha aprobado: ${setFecha(row.motivo)}
                                      Fecha pagado: ${setFecha(row.updated_at)}`}
                                    </Typography>
                                  }
                                  arrow
                                >
                                  <button
                                    style={{
                                      background: "transparent",
                                      color: "#554CE5",
                                      cursor: "pointer",
                                      position: "relative",
                                      borderRadius: "50%",
                                      padding: "1px 9px 1px 9px",
                                      margin: "6px 0px 0px 6px",
                                    }}
                                  >
                                    PAGADO
                                  </button>
                                </Tooltip> : <></>}
                              </StyledTableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>

                <PaginatorServer
                  xs={12}
                  md={12}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  page={page}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  length={totalRegs}
                />
              </ThemeProvider>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <ModalAceptarAnticipo
        isVisible={ShowModalPagar}
        onClose={() => setShowModalPagar(false)}
        tarjeta={pagarTarjeta}
        user={user}
      // actualizarPago={actualizarPago}
      />
      <ModalNegarAnticipo
        isVisible={ShowModalNegar}
        onClose={() => setShowNegar(false)}
        tarjeta={negar}
        user={user}
      // actualizarPago={actualizarPago}
      />
    </>
  );
};
